export default {
  address: '新北市林口區文化三路一段 & 忠孝三路',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d903.492388806323!2d121.36114082925653!3d25.06902099899725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMjXCsDA0JzA4LjUiTiAxMjHCsDIxJzQyLjEiRQ!5e0!3m2!1szh-TW!2stw!4v1566448452968!5m2!1szh-TW!2stw',
  phone: '02-26095500',
  fbLink: 'https://www.facebook.com/蘭桂坊-102691431093421/',
  fbMessage: 'https://m.me/102691431093421/',
  googleLink: 'https://goo.gl/maps/nSYjM15fL514PSRQ9',
  caseName: '蘭桂坊',

  houseInfos: [
    ['聯絡電話', '02-26095500'],
    ['投資興建', '青山興業股份有限公司'],
    ['建築設計', '翁清源建築師事務所'],
    ['廣告銷售', '城洲廣告股份有限公司'],
    ['使用分區', '住二用地'],
    ['建照號碼', '108林建字第361號']
  ],

  gtmCode: ['KXKZVF4'], // 可放置多個
  order: {
    title: '預約賞屋',
    subTitle: ''
  }
}
